@import 'sweetalert2/src/variables';

// BOX MODEL
$swal2-border-radius: 0;
$swal2-box-shadow: none;

// BACKDROP
$swal2-backdrop-transition: initial;

// ICONS
$swal2-icon-animations: false;

// INPUT
$swal2-input-border-radius: 0;
$swal2-input-box-shadow: none;
$swal2-input-box-shadow-focus: none;
$swal2-input-transition: initial;

// BUTTONS
$swal2-confirm-button-border-radius: 0;
$swal2-cancel-button-border-radius: 0;

// ANIMATIONS
$swal2-show-animation: none;
$swal2-hide-animation: none;

// CLOSE BUTTON
$swal2-close-button-transition: initial;

// TOASTS
$swal2-toast-show-animation: none;
$swal2-toast-hide-animation: none;
$swal2-toast-success-line-tip-animation: none;
$swal2-toast-success-line-long-animation: none;
$swal2-toast-border: 1px solid lighten($swal2-black, 85);
$swal2-toast-box-shadow: none;

.swal2-container{
  z-index: 1301 !important;
}
.swal2-container.swal2-center {
 & > .swal2-popup {
    background-color: rgb(255, 255, 255);
    border: 0px solid rgb(0, 0, 0);
    border-radius: 0px;
    box-shadow: rgb(140 152 164 / 18%) 0px 10px 40px 10px;
    padding: 32px 0;
   position: relative;
    &:after{
      content: '';
      bottom: 10px;
      left: 0;
      right: 0;
      height: 3px;
      background-color: #331589;
      position: absolute;
    }
   .swal2-icon{
     width: 2.5em;
     height: 2.5em;
     margin: 1em auto 0.6em;
     .swal2-icon-content{
       font-size: 2em;
     }
   }
   .swal2-title{
     font-size: 2.0243rem;
     color: #4A4B4B;
     font-weight: 700;
   }
   .swal2-html-container{
     font-weight: 400;
     font-size: 1rem;
     line-height: 1.5;
     color: #4A4B4B;
     margin-top: 16px;
   }
   .swal2-styled{
     min-width: 147px;
     padding: 11px 16px;
     &.swal2-confirm{
       background-color: rgb(51, 21, 137);
       box-shadow: rgb(140 152 164 / 18%) 0px 10px 40px 10px;
       border-radius: 4px;
       transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
       color: rgb(255, 255, 255);
       font-size: 0.875rem;
       line-height: 1.75;
       &:hover,&:focus,&:active{
         background-color: rgb(94, 53, 209);
         box-shadow: rgb(140 152 164 / 18%) 0px 10px 40px 10px;
       }
     }
     &.swal2-deny{
       border-radius: 4px;
       background-color: transparent;
       transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
       border: 1px solid rgba(51, 21, 137, 0.5);
       color: rgb(51, 21, 137);
       font-size: 0.875rem;
       line-height: 1.75;
       &:hover,&:focus,&:active{
         background-color: rgba(51, 21, 137, 0.04);
       }
     }
   }
  }
}





@import 'sweetalert2/src/sweetalert2';